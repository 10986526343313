<template>
    <div class="pc page">

        <!-- 头部 -->
        <div class="title" :style="'padding-left: ' + formOffsetLeft + 'px;'">
            <h1>{{$t('layout.contactUs')}}</h1>
            <el-breadcrumb v-if="$responsive.is('pc')">
                <el-breadcrumb-item>
                    <router-link to="/">{{$t('layout.homePage')}}</router-link>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('layout.contactUs')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 主要内容 -->
        <div class="content">
            <!-- 左侧表单 -->
            <el-form ref="contactForm" :model="contactFormData" label-width="80px" class="contact-form">
                <el-form-item class="is-required" :label="$t('contactUs.name')">
                    <el-input class="form-item" v-model="contactFormData.name" :placeholder="$t('contactUs.name')" maxlength="256"></el-input>
                </el-form-item>
                <el-form-item :label="$t('contactUs.tel')">
                    <el-input class="form-item" v-model="contactFormData.phone" :placeholder="$t('contactUs.tel')" maxlength="64"></el-input>
                </el-form-item>
                <el-form-item class="is-required" :label="$t('contactUs.email')">
                    <el-input class="form-item" v-model="contactFormData.email" :placeholder="$t('contactUs.email')" maxlength="64"></el-input>
                </el-form-item>
                <el-form-item :label="$t('contactUs.qq')">
                    <el-input class="form-item" v-model="contactFormData.qq" :placeholder="$t('contactUs.qq')" maxlength="32"></el-input>
                </el-form-item>
                <el-form-item :label="$t('contactUs.content')">
                    <el-input class="form-item" type="textarea" v-model="contactFormData.content" :placeholder="$t('contactUs.content')"
                              :autosize="{minRows: 5, maxRows: 10}" maxlength="1000"></el-input>
                </el-form-item>
                <el-form-item>
                    <span @click="submitContact" class="submit-btn">{{$t('contactUs.submit')}}</span>
                </el-form-item>
            </el-form>
            <!-- 右侧联系方式 -->
            <div v-if="$responsive.is('pc')" class="contacts">
                <div class="contact-item">
                    <div class="icon-location"></div>
                    <div class="contact-label">{{$t('layout.address')}}： </div>
                    <div class="contact-text">{{$t('layout.addressValue')}}</div>
                </div>
                <div class="contact-item">
                    <div class="icon-contact-person"></div>
                    <div class="contact-label">{{$t('layout.contact')}}： </div>
                    <div class="contact-text">{{$t('layout.contactValue')}}</div>
                </div>
                <div class="contact-item">
                    <div class="icon-phone1"></div>
                    <div class="contact-label">{{$t('layout.cellTel')}}： </div>
                    <div class="contact-text">{{$t('layout.cellTelValue')}}</div>
                </div>
                <div class="contact-item">
                    <div class="icon-phone"></div>
                    <div class="contact-label">{{$t('layout.tel')}}： </div>
                    <div class="contact-text">{{$t('layout.telValue')}}</div>
                </div>
                <div class="contact-item">
                    <div class="icon-fax"></div>
                    <div class="contact-label">{{$t('layout.fax')}}： </div>
                    <div class="contact-text">{{$t('layout.faxValue')}}</div>
                </div>
                <div class="contact-item">
                    <div class="icon-email"></div>
                    <div class="contact-label">{{$t('layout.email')}}： </div>
                    <div class="contact-text">{{$t('layout.emailValue')}}</div>
                </div>
                <div class="contact-item">
                    <div class="icon-duihua"></div>
                    <div class="contact-label">{{$t('layout.zip')}}： </div>
                    <div class="contact-text">{{$t('layout.zipCode')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import EventUtils from '../utils/event-utils';

    export default {

      data() {
        return {
          contactFormData: {},
          formOffsetLeft: 10,
        };
      },

      mounted() {
        window.vue = this;
        this.formOffsetLeft = this.$refs.contactForm.$el.offsetLeft;
        this.resizeRecalculateOffset = EventUtils.debounce(() => {
          this.formOffsetLeft = this.$refs.contactForm.$el.offsetLeft;
        }, 500);
        window.addEventListener('resize', this.resizeRecalculateOffset);
      },

      destroy() {
        if (this.resizeRecalculateOffset) {
          window.removeEventListener('resize', this.resizeRecalculateOffset);
        }
      },

      methods: {
        submitContact() {
          console.info(this.contactFormData);
          this.$ajax.post('/contact-record', this.contactFormData).then(response => {
            if (response.code !== 100) {
              return;
            }

            this.$message.success('已经提交反馈，我们会在稍后联系您。');
            this.contactFormData = {};
          });
        }
      }
    }


</script>


<style scoped lang="scss">
    .page {
        background-color: var(--white);
    }

    .title {
        width: 100%;
        max-width: 1366px;
        margin: 0 auto;

        display: flex;
        align-items: flex-end;
        padding-top: 50px;

        h1 {
            margin: 0 40px 20px 0;
            font-size: 38px;
            color: var(--black);
            @media screen and (max-width: 768px) {
                font-size: 30px;
                margin: 0 0px 0px 20px;
            }
        }

        .el-breadcrumb {
            margin: 0 0 30px 0;
            font-size: 18px;
        }
    }

    .content {
        width: 100%;
        max-width: 1366px;
        margin: 0 auto;

        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 80px;

        .contact-form {
            flex: 1;
            max-width: 400px;

            .form-item {
                width: 100%;
                @media screen and (max-width: 768px) {
                    width: 80%;
                }
            }

            .submit-btn {
                display: inline-block;
                height: 40px;
                padding: 0 40px;
                color: var(--white);
                background-color: #409EFF;
                border-radius: 6px;
                font-size: 14px;
                line-height: 40px;
                cursor: pointer;

                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                @media screen and (max-width: 768px) {
                    margin-left: 0;

                }

                &:hover {
                    background-color: rgb(121, 187, 255);
                }
            }
        }

        .contacts {
            flex: 1;
            max-width: 600px;

            .contact-item {
                display: flex;
                align-items: flex-start;
                margin-left: 80px;
                margin-bottom: 22px;
                @media screen and (max-width: 1080px) {
                    margin-left: 30px;
                }

                font-size: 16px;
                line-height: 40px;

                .contact-label {
                    min-width: 100px;
                }

                .contact-text {
                    flex: 1;
                }

                [class*=icon] {
                    margin-right: 20px;
                    font-size: 32px;
                }
            }
        }

    }

</style>